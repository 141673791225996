import { FormFieldRecord, ImageEditorOptions, ImageFileOptions, ImageRecord, InfoPageStylesRecord } from './lib-api-interfaces';

export enum BlogListTypeEnum { recent = 'R', archive = 'A', topic = 'T', search = 'S', singleEntry = 'E', memorial = 'M' }
// following returned from a search or click on archive month or click on topic
// app can load entries based on current date (default), topic, month/year or search term
// one page of entries is returned at a time (default to 10 entries); app can request next block when user clicks "show more posts"
export interface BlogEntryList {
    criteria: BlogEntryListCriteria;    // what was passed to api to retrieve list
    entries: BlogEntryRecord[]; // reverse chron
}
// pass this object to api in loadBlogEntryList
// list_type and criteria and passed in to request first page of data, and never change
// hits is returned with first page and never changes after that
// start_index is incremented by page_size with each request
// hits, start_index and page_size are ignored if loading single entry
export interface BlogEntryListCriteria {
    list_type: BlogListTypeEnum;
    criteria?: string;      // format: recent: undefined; archive: season/year; topic: topic label; search: search text; singleEntry: id# (nnnnnnn)
    hits?: number[];        // if not 1st page this is passed in and returned as-is; on 1st page this is loaded by api
    start_index?: number;   // pointer into hits (if hit undefined this will be assumed to be 0)
    page_size?: number;      // how many records to return
}
export interface BlogEntryRecord {
    entry_id: number;       // e.gl: 202203110 for 11/3/22 first entry
    title?: string;         // assigned by karina or blog author
    pub_date: string;
    content: string;        // html
    comments?: BlogCommentRecord[];     // only returned when one entry is loaded
    comment_count?: number;             // only returned when list is loaded
    topics: string[];
    popular?: BlogEntryRecord[];        // valid only when requesting single post
}
export interface BlogCommentRecord {
    entry_id: number;
    uuid?: string;      // assigned by server so for fetch only
    pub_date: string;   // when first submitted
    mod_date?: string;   // default to pub_date
    content: string;
    author_handle: string;
    reply_to?: string;       // uuid
    notify_date?: string;   // when K was notified by email
    approval_date?: string; // when K approved for posting
}
export interface BlogCommentPostRecord extends BlogCommentRecord {
    notify: boolean;
    email: string;
    subscribe: boolean;
    remember?: boolean;     // for client only; not passed in api
}
export interface BlogUserRecord {
    author_handle: string;
    email: string;
    notify: boolean;
    subscribe: boolean;
}

// can be for archive or topics
export interface BlogArchiveRecord {
    caption: string;        // e.g. "July 2022" or "karinaland"
    url: string;   // must include list_type and criteria; e.g. { list_type: BlogListTypeEnum.mmyy, criteria: "0722" }
    entry_count: number;
}
// following returned from /api/getBlogHomeRecord and client useData.getArchiveData
export interface BlogArchiveData {
    archive: BlogArchiveRecord[];
    topics: BlogArchiveRecord[];
}

// api_websites.contentV2.content_type
export enum WebsiteContentType { text = 't', textList = 'tl', textPair = 'tp', photo = 'p', record = 'r' }

/* ContentType:
    text: "t" -- stored as string
    text-list: "tl" -- stored as array
    text-pair: "tp" -- stored as array of { label: string, text: string }[]
    photo: "p" -- stored as WebsitePhotoContentType
    record: "r" -- array of objects containing multiple fields as a data row
*/

// following are stored as json in the api_websites.contentV2.content field
export type WebsiteTextContentType = { styles: InfoPageStylesRecord, text: string };
export type WebsiteTextListContentType = string[];
export type WebsiteTextPairContentType = { label: string; text: string }[];
export type WebsitePhotoContentType = { imageOptions: ImageEditorOptions, images: ImageRecord[] };
export interface WebsiteRecordContentRecord {
    column_defs: FormFieldRecord[];
    sort_column: string;
    content: Record<string, any>[];     // as defined by record_defs
}
// following are not editable by the dashboard and live in the contentV2 record
// there can be multiple tag records per page
export interface WebsiteTagRecord {
    // first 3 are page level; will always be the same for all tags on the page
    domain: string;         // the website domain
    is_dashboard: boolean;  // true if this is not yet in production
    page: string;                // e.g. "home" or "press"
    tag: string;                // a page can have multiple records, each with a unique tag (e.g.: page="story", tag="above" or "below")
    content_type: WebsiteContentType;  // determines the record layout per extensions below; it is stored as JSON and returns json on intermediate api calls
    caption: string;            // title for dashboard editor
    content: WebsiteContentRecord | string;     // if string it is JSON for storage in DB table
}
// returned by api dashboard get content call
export type WebsiteContentRecord = WebsiteTextContentType | WebsiteTextListContentType | WebsiteTextPairContentType | WebsitePhotoContentType | WebsiteRecordContentRecord;

/*

// returned from getDashboardContent api call
export interface GetDashboardContentResultRecord {
    isDashboard: boolean;
    content: DashboardContentRecord[];
}
// record returned to web site is as follows: { tag1: content, tag2: content ... etc } where tagx is value of tag field and content is parsed content field
// the following are for the dashboard editors
export type DashboardContentFieldType = string | string[] | { label: string; text: string }[] | DashboardPhotosRecord | DashboardRecordRecord;   // shortcut
export interface DashboardContentRecord {
    domain: string;         // the website domain
    is_dashboard: boolean;  // true if this is not yet in production
    url: string;                // e.g. "home" or "press"
    content_type: DashboardContentType;  // determines the record layout per extensions below
    caption: string;            // title for dashboard editor
    tag: string;
    // in the following fields replace "...Content" with [tag] for the result returned to web sites
    // e.g.: tag="appList", content_type=(textListContent) returns { appList: ["one", "two", "three"] }
    // they are stored as JSON in the content field of dbo.content table
    content: DashboardContentFieldType;     // this is stored as JSON in dbo.content; parsed/stringified from/to DashboardContentFieldType by api
}
// following for uploading image for processing
export interface GraphicProcessingRecord extends ImageRecord {
    // file is required
    outputMagnified?: boolean;
    field?: string;
}

export interface DashboardRecordRecord {
    record_defs: FormFieldRecord[];
    sort_column: string;
    records: Record<string, any>[];         // only this is returned to website; rest is for dashboard
}
*/
