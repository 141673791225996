import React from "react";
import styled from 'styled-components';

const StyledContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 3000;
  `
const StyledSpinner = styled.div<{ windowHeight: number }>`
    font-size: 100px;
    position: fixed;
    top:50%; 
    left:50%; 
    transform: translate(-50%, -50%); 
    z-index: 3100;
`
/*
const StyledSpinner = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 48px;
`
*/
const Spinner: React.FC = () => {
    return (
        <StyledContainer>
        <StyledSpinner windowHeight={window.innerHeight}>
            <i className="fa fa-spinner fa-spin"></i>
        </StyledSpinner>
        </StyledContainer>
    );
}
export default Spinner;
