import { useState } from 'react';
import axios, { Method } from 'axios';

import { useGlobalContext } from './SamState';

enum LogLevel { none = -1, error = 0, url = 1, full = 2 }
const logging = LogLevel.full;
const log = (logLevel: LogLevel, msg: string, obj?: any) => {
    if (logging >= logLevel) {
        if (obj) {
            console.log(msg, obj);
        } else {
            console.log(msg);
        }
    }
}

const publicToken = "BTicD0xEwvcT6tOfHkuqkTmcZfZXr/kxSCK+ByQbaq55ZTdNaOppSq22bFaDKfeQ4oQu2sSx0oBdriNvOcK0uH+AhMhiVf9c";     // when decoded by api it is the secret password; sent with all non-sensitive calls
// following must agree with declaration in useTableGrid
/* following are supported data types:
    new dataTypeLookupInfo("int", sqlTypes.Int),
    new dataTypeLookupInfo("char", sqlTypes.Char),
    new dataTypeLookupInfo("datetime", sqlTypes.DateTime),
    new dataTypeLookupInfo("varchar", sqlTypes.NVarChar),
    new dataTypeLookupInfo("decimal", sqlTypes.Decimal),
     new dataTypeLookupInfo("money", sqlTypes.Money),
   new dataTypeLookupInfo("smalldatetime", sqlTypes.SmallDateTime),
    new dataTypeLookupInfo("bit", sqlTypes.Bit),
    new dataTypeLookupInfo("smallint", sqlTypes.SmallInt),
    new dataTypeLookupInfo("bigint", sqlTypes.BigInt)
*/

// export type DataFetchProps = {
//     url: string; token: string | null; successCallback?: ApiResultCallback; errorCallback?: ApiResultCallback;
// };
export const apiStateKey = "apiState";      // holds the time of last pending api call, used by MasterPage to display spinner

export interface ApiResultCallback {
    (data: any, status?: number): void;
}
export const genericApiError = "We're so sorry. We are having problems processing your request right now. Please try again later!";

// method is "post" or "get"
export async function asyncApi(method: Method, url: string, data: any, token: string | null, isMultiPart: boolean = false): Promise<any> {
    log(LogLevel.full, "asyncApi called with method=" + method + ", url=" + url + ", data:", data);
    const pw = token ? token : publicToken;
    let headers: Record<string, any> = { "Authorization": `Bearer ${pw}` }
    if (isMultiPart) {
        headers['Content-Type'] = 'multipart/form-data';
    }
    const result = await axios({ method, url, data, headers });
    log(LogLevel.full, "asyncApi is returning with:", result);
    return result.data;
}

/* OBSOLETE state object: keys are urls, values are objects with following fields:
    data: object returned from fetch
    isError: bool -- true if fetch returned error
    isLoading: bool -- true while waiting for fetch to return
    isCompleted: bool -- true when fetch has returned with either error or data
    errorStack: object -- may have info if isError true
    friendlyErrorMessage: string -- error message if isError true
*/
// state object is { url1: isLoading, url2: isLoading ... }
// following is passed to success callback as an array
export interface MultiApiResultRecord {
    data: any;
    status: number;
}
export interface MultiPostRecord {
    url: string;
    data: any;
}
export const useFetchApi = () => {
    const [fetchState, setFetchState] = useState<Record<string, boolean>>({});

    const { setContext } = useGlobalContext();

    /* state object: keys are urls, values are objects with following fields:
        data: object returned from fetch
        isError: bool -- true if fetch returned error
        isLoading: bool -- true while waiting for fetch to return
        isCompleted: bool -- true when fetch has returned with either error or data
        errorStack: object -- may have info if isError true
        friendlyErrorMessage: string -- error message if isError true
    */
    // skip url to see if ANY fetch is loading
    const isFetchLoading = (url?: string): boolean => {
        if (url) {
            return fetchState[url];
        } else {
            for (const key in fetchState) {
                if (fetchState[key]) {
                    return true;
                }
            }
            return false;
        }
    }
    // returns array of objects as follows: [{ data: result-from-fetch, status: number }, ...]
    const multiFetch = (urls: string[], token: string | null, successCallback: ApiResultCallback, errorCallback: ApiResultCallback) => {
        log(LogLevel.url, "axios is fetching from ", urls);
        setFetchState(state => {
            const newState = { ...fetchState };
            newState["multi"] = true;
            return newState;
        });
        setContext(apiStateKey, Date.now());

        const pw = token ? token : publicToken;
        log(LogLevel.url, "token=" + token);
        axios.all(urls.map(url => {
            return (
                axios.get(url, { headers: { "Authorization": `Bearer ${pw}` } })
            );
        }))
            .then(axios.spread((function (...responses) {

                const responseArray: MultiApiResultRecord[] = responses.map(response => {
                    return (
                        { data: response.data, status: response.status }
                    );
                });
                setFetchState(state => {
                    const newState = { ...fetchState };
                    newState["multi"] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                try {
                    log(LogLevel.url, "returning from multiFetch with:", responseArray);
                    successCallback(responseArray);
                } catch { }
            })))
            .catch(error => {
                log(LogLevel.error, "axios returned error: " + error + " from:", urls);
                setFetchState(state => {
                    const newState = { ...fetchState };
                    newState["multi"] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                errorCallback(error.response && error.response.data && error.response.data.message ? error.response.data.message : error, 500);
            });
        //       }, 2000);
    }

    // callbacks optional; if not given check state for data
    // following simulates a browser fetch
    const httpFetch = (url: string, successCallback: ApiResultCallback, errorCallback: ApiResultCallback) => {
        log(LogLevel.url, "axios is http fetching from " + url);
        axios(url).then(result => {
            log(LogLevel.full, "axios returned success from " + url + " with", result);
            setFetchState(state => {
                const newState = { ...fetchState };
                newState[url] = false;
                return newState;
            });
            setContext(apiStateKey, null);
            try {
                successCallback(result.data, result.status);
            } catch { }
        })
            .catch(error => {
                log(LogLevel.error, "axios returned error from " + url + ":", error);
                setFetchState(state => {
                    const newState = { ...fetchState };
                    newState[url] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                errorCallback(error.response && error.response.data && error.response.data.message ? error.response.data.message : error, 500);
            });
    }

    const fetch = (url: string, token: string | null, successCallback: ApiResultCallback, errorCallback: ApiResultCallback, params?: Record<string, any>) => {
        log(LogLevel.url, "axios is fetching from " + url);
        if (params) {
            log(LogLevel.full, "fetch params:", params);
        }
        setFetchState(state => {
            const newState = { ...fetchState };
            newState[url] = true;
            return newState;
        });
        setContext(apiStateKey, Date.now());

        const pw = token ?? publicToken;
        log(LogLevel.url, "token=" + token);
        const headers = { "Authorization": `Bearer ${pw}` };

        axios.get(url, { headers, params })
            .then(result => {
                log(LogLevel.full, "axios returned success from " + url + " with", result);
                setFetchState(state => {
                    const newState = { ...fetchState };
                    newState[url] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                try {
                    successCallback(result.data, result.status);
                } catch { }
            })
            .catch(error => {
                log(LogLevel.error, "axios returned error from " + url + ":", error);
                setFetchState(state => {
                    const newState = { ...fetchState };
                    newState[url] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                errorCallback(error.response && error.response.data && error.response.data.message ? error.response.data.message : error, 500);
            });
        //       }, 2000);
    }
    return { fetch, httpFetch, multiFetch, isFetchLoading };
}
export const usePostApi = () => {
    const [postState, setPostState] = useState<Record<string, boolean>>({});
    const { setContext } = useGlobalContext();

    // url entry can be null to skip post; data entry will be ignored
    const multiPost = (postData: MultiPostRecord[], successCallback: ApiResultCallback, errorCallback: ApiResultCallback, token: string | null = null) => {
        postData.forEach(record => {
            log(LogLevel.url, "multiposting to " + record.url);
            log(LogLevel.full, "multiposting data:", record.data);
        });
        setPostState(state => {
            const newState = { ...postState };
            newState["multi"] = true;
            return newState;
        });
        const pw = token ? token : publicToken;
        let headers: Record<string, any> = { "Authorization": `Bearer ${pw}` }
        setContext(apiStateKey, Date.now());
        axios.all(postData.map(record => {
            return (
                axios({ method: 'post', url: record.url, data: record.data, headers })
            );
        }))
            .then(axios.spread((function (...responses) {
                const responseArray: MultiApiResultRecord[] = responses.map(response => {
                    return (
                        { data: response.data, status: response.status }
                    );
                });
                setPostState(state => {
                    const newState = { ...postState };
                    newState["multi"] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                try {
                    successCallback(responseArray);
                } catch { }
            })))
            .catch(error => {
                log(LogLevel.error, "axios returned error: " + error + " from " + postData[0].url + " et al");
                setPostState(state => {
                    const newState = { ...postState };
                    newState["multi"] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                errorCallback(error.response && error.response.data && error.response.data.message ? error.response.data.message : error, 500);
            });
    }
    const post = (url: string, data: any, successCallback: ApiResultCallback, errorCallback: ApiResultCallback, token: string | null = null, isMultiPart: boolean = false) => {
        log(LogLevel.url, "posting to " + url);
        log(LogLevel.full, "posting data:", data);
        setPostState(state => {
            const newState = { ...postState };
            newState[url] = true;
            return newState;
        });
        const pw = token ? token : publicToken;
        let headers: Record<string, any> = { "Authorization": `Bearer ${pw}` }
        if (isMultiPart) {
            headers['Content-Type'] = 'multipart/form-data';
        }
        setContext(apiStateKey, Date.now());
        axios({ method: 'post', url, data, headers })
            .then(res => {
                log(LogLevel.full, "postData returned success: res.status=" + res.status + ", res.data:", res.data)
                setPostState(state => {
                    const newState = { ...postState };
                    newState[url] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                try {
                    log(LogLevel.full, "Post returned from " + url + " with:", res.data);
                    successCallback(res.data, res.status);
                } catch (error) {
                    log(LogLevel.error, "error in successCallback: ", error);
                }
            })
            .catch((error) => {
                log(LogLevel.error, "post returned error:", error);
                setPostState(state => {
                    const newState = { ...postState };
                    newState[url] = false;
                    return newState;
                });
                setContext(apiStateKey, null);
                errorCallback(genericApiError, 500);
            });
        //       }, 10000);
    }
    const isPostLoading = (url?: string): boolean => {
        if (url) {
            return postState[url] && postState[url];
        } else {
            for (const key in postState) {
                if (postState[key]) {
                    return true;
                }
            }
            return false;
        }
    }

    return { post, multiPost, isPostLoading };
}


